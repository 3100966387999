import React from "react"
import { ProductDetails, SEO } from "../components"

export default function ProductTemplate({ pageContext, location }) {
  return (
    <>
      <SEO title={`Arreglo Floral - ${pageContext.title}`} />
      <ProductDetails {...pageContext} location={location} />
    </>
  )
}
